<template>
  <div class="hello">
    <img src="https://media4.giphy.com/media/q0eh4TU8OMXRu/giphy.gif?cid=ecf05e471ou2q1ys970suez9yznxlxg9q78orx6wkj05v8oh&amp;rid=giphy.gif&amp;ct=g" >
    <h1>This page is under constructrion, please come back later</h1>
    <p>Email: <a href="mailto:admin@pentatope.tech">admin@pentatope.tech</a></p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  padding: 40px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
